'use client';

import Image from 'next/image';
import React, { FC } from 'react';

import PartButton from '@/components/UI/part-button/part-button';
import { IService } from '@/contentful/utils/services';

import styles from './part-service-cart.module.scss';

interface IPartServiceCartProps {
  lang: string;
  service: IService;
}

const PartServiceCart: FC<IPartServiceCartProps> = ({ service, lang }) => {
  let slug;
  if (service.slug) {
    const baseSlug = lang === 'lv' ? '/services' : `/${lang}/services`;
    slug = `${baseSlug}/${service.slug}`;
  } else {
    slug = undefined;
  }

  return (
    <div className={styles.card}>
      <div className={styles.imageWrap}>
        <Image
          src={service.tileImage?.src ?? ''}
          alt={service.tileImage?.alt ?? ''}
          width={service.tileImage?.width ?? 0}
          height={service.tileImage?.height ?? 0}
          className={styles.image}
          quality={75}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.iconWrap}>
            <div className={styles.iconContainer}>
              <Image
                src={service.icon?.src ?? ''}
                alt={service.icon?.alt ?? ''}
                width={service.icon?.width ?? 0}
                height={service.icon?.height ?? 0}
                className={styles.image}
              />
            </div>
          </div>
          <div className={styles.titleWrap}>
            <h3 className={styles.title}>{service.title}</h3>
          </div>
        </div>
        <ul className={styles.text}>
          {service.tileList.map((item) => (
            <li key={item + service.title} className={styles.textItem}>
              {item}
            </li>
          ))}
        </ul>
        <div className={styles.buttonWrap}>
          <PartButton
            option="transparent"
            text={service.button ?? ''}
            slug={slug}
          />
        </div>
      </div>
    </div>
  );
};

export default PartServiceCart;
