import React, { FC, ReactNode } from 'react';

import IconLinesLeft from './assets/icon-line-left-small.svg';
import IconLinesRight from './assets/icon-line-right-small.svg';

import styles from './part-section-container.module.scss';

interface IPartSectionContainerProps {
  advantage: string;
  title: string;
  description?: string;
  children?: ReactNode;
}

const PartSectionContainer: FC<IPartSectionContainerProps> = ({
  advantage,
  title,
  description,
  children,
}) => (
  <div className={styles.partSectionContainer}>
    <div className={styles.advantageContainer}>
      <IconLinesLeft />
      <p className={styles.advantage}>{advantage}</p>
      <IconLinesRight />
    </div>
    <div className={styles.titleWrap}>
      <h2 className={styles.title}>{title}</h2>
      {description && <p className={styles.description}>{description}</p>}
    </div>
    {children}
  </div>
);

export default PartSectionContainer;
