'use client';

/* eslint-disable no-console */
/* eslint-disable no-alert */

import cx from 'classnames';
import omit from 'lodash/omit';
import React, {
  useState, useRef, FC, ChangeEvent, FormEvent,
} from 'react';

import { formsBaseUrl, legalConsentOptions } from '../../../../site-config';
import PartCheckbox from '@/components/UI/part-checkbox';
import PartInput from '@/components/UI/part-input';
import { IForm } from '@/contentful/utils/form';

import styles from './part-contact-form.module.scss';

interface FormData {
  firstname?: string | undefined;
  email?: string;
  phone?: string;
  message?: string;
}

interface IPartContactFormProps {
  dataForm: IForm;
}

const PartConatctForm: FC<IPartContactFormProps> = ({ dataForm }) => {
  const {
    title,
    nameFields,
    phoneField,
    emailField,
    messageField,
    button,
    formSuccessText,
    formSuccessTitle,
    errorMessageMin,
    errorMessageName,
    errorMessageEmail,
    errorMessagePhone,
    labelCheckBox,
  } = dataForm;

  const [formData, setFormData] = useState<FormData>({});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prevFormErrors) => omit(prevFormErrors, [e.target.name]));
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const validateSubmit = () => {
    const errors: { firstname?: string; email?: string; phone?: string } = {};
    const { firstname, email, phone } = formData;

    if (firstname && firstname.length < 2) {
      errors.firstname = errorMessageMin ?? 'Min 2 characters';
    }
    if (!firstname) errors.firstname = errorMessageName ?? 'Please enter your name';
    if (!email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors.email = errorMessageEmail ?? 'Please enter a valid email';
    }
    if (!phone?.match(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)) {
      errors.phone = errorMessagePhone ?? 'Please enter a valid phone';
    }

    setFormErrors(errors);

    const isValid = Object.keys(errors).length === 0;

    return isValid;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateSubmit()) return false;

    const fields = [];

    fields.push(
      ...Object.entries(formData).map(([k, v]) => ({
        name: k,
        value: v,
      })),
    );

    const currentUrl = window.location.pathname;

    fields.push({
      name: 'conversion_url',
      value: currentUrl,
    });

    fetch(`${formsBaseUrl}/8affdcce-f7d7-46cb-9ed3-2e2f1160f079`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fields, legalConsentOptions }),
    })
      .then(() => {
        setFormData({});
        setFormErrors({});
        setFormSuccess(true);
      })
      .catch((error) => {
        alert('Something went wrong. Please try again later.');
        console.log(error);
      });
  };
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
        <div className={styles.formInputsContainer}>
          <div className={styles.formInput}>
            <PartInput
              name="firstname"
              placeholder={nameFields}
              onChange={handleChangeInput}
              value={formData.firstname}
              inputError={formErrors.firstname}
            />
          </div>
          <div className={styles.formInput}>
            <PartInput
              name="email"
              placeholder={emailField}
              onChange={handleChangeInput}
              value={formData.email}
              inputError={formErrors.email}
            />
          </div>
          <div className={styles.formInput}>
            <PartInput
              name="phone"
              placeholder={phoneField ?? ''}
              onChange={handleChangeInput}
              value={formData.phone}
              inputError={formErrors.phone}
            />
          </div>
          <div className={cx(styles.formInput, styles.formInput_message)}>
            <PartInput
              type="textarea"
              name="message"
              placeholder={messageField ?? ''}
              value={formData.message || ''}
              onChange={handleChangeInput}
            />
          </div>
          <PartCheckbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            label={labelCheckBox ?? ''}
          />
        </div>
        <div className={styles.submitButtonContainer}>
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <button
            className={styles.submitButton}
            type="submit"
            onClick={validateSubmit}
            disabled={Object.keys(formErrors).length > 0 || !isChecked}
            title={
              Object.keys(formErrors).length > 0 ? 'Form has errors' : undefined
            }
          >
            <span>{button}</span>
          </button>
        </div>
      </form>
      {formSuccess && (
        <div className={styles.formSuccess}>
          <h3 className={styles.formSuccessTitle}>
            {formSuccessTitle ?? 'Thank you!'}
          </h3>
          <p className={styles.formSuccessText}>
            {formSuccessText ?? 'We will contact you soon.'}
          </p>
        </div>
      )}
    </div>
  );
};

export default PartConatctForm;
