'use client';

import React, { FC } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IReview } from '@/contentful/utils/reviews';

import IconArrowSide from './assets/icon-arrow-side.svg';
import IconLinesLeft from './assets/lines-left.svg';
import PartReviewCart from './part-review-card/part-review-card';

import styles from './part-reviews.module.scss';

interface PartReviewsProps {
  dataReview: {
    category: string;
    title: string;
  };
  reviews: IReview[];
}

const PartReviews: FC<PartReviewsProps> = ({ dataReview, reviews }) => (
  <div className={styles.container}>
    <div className={styles.advantageContainer}>
      <IconLinesLeft />
      <p className={styles.advantage}>{dataReview.category}</p>
    </div>
    <h2 className={styles.title}>{dataReview.title}</h2>
    <div className={styles.reviewsList}>
      <Swiper
        className={styles.swiperContainer}
        modules={[Navigation]}
        pagination={{ clickable: true }}
        {...{
          slidesPerView: 'auto',
          navigation: {
            nextEl: `.${styles.reviewNext}`,
            prevEl: `.${styles.reviewPrev}`,
          },
          breakpoints: {
            0: {
              enabled: true,
              slidesPerView: 1,
              spaceBetween: 10,
            },
          },
        }}
      >
        {reviews.map((review) => (
          <SwiperSlide className={styles.swiperSlide} key={review.title}>
            <PartReviewCart review={review} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.reviewNavigation}>
        <div className={styles.reviewPrev}>
          <IconArrowSide />
        </div>
        <div className={styles.reviewNext}>
          <IconArrowSide />
        </div>
      </div>
    </div>
  </div>
);

export default PartReviews;
