'use client';

import Image from 'next/image';
import React, { FC } from 'react';
import Marquee from 'react-fast-marquee';

import { IListItem } from '@/contentful/utils/listItem';

import styles from './part-partner.module.scss';

interface IPartPartnersProps {
  data: {
    title: string | undefined;
    partners: IListItem[];
  };
}

const PartPartners: FC<IPartPartnersProps> = ({ data }) => (
  <section className={styles.container}>
    <h2 className={styles.title}>{data.title}</h2>
    <Marquee autoFill>
      {data.partners.map((partner) => (
        <div key={`${partner.title}firstList`} className={styles.iconWrap}>
          <Image
            src={partner.icon?.src ?? ''}
            alt={partner.icon?.alt ?? ''}
            width={partner.icon?.width ?? 0}
            height={partner.icon?.height ?? 0}
          />
        </div>
      ))}
    </Marquee>
  </section>
);

export default PartPartners;
