/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */

import cx from 'classnames';
import React, { FC, ChangeEvent, ElementType } from 'react';

import styles from './part-input.module.scss';

interface IPartInputProps {
  className?: string;
  value: string | undefined;
  name: string;
  type?: 'text' | 'textarea';
  placeholder: string;
  maxLength?: number;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  inputError?: string;
}

const PartInput: FC<IPartInputProps> = ({
  className,
  value = '',
  name,
  placeholder,
  maxLength,
  inputError,
  onChange,
  type = 'text',
}) => {
  const Element: ElementType = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div
      className={cx(
        styles.container,
        { [styles.hasError]: !!inputError },
        className,
      )}
    >
      <Element
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
      />
      <div className={styles.errorIcon}>!</div>
      <i className={styles.errorText}>{inputError}</i>
    </div>
  );
};

export default PartInput;
