'use client';

import React, { FC } from 'react';

import PartCasesContainerMain from '@/components/part-cases-container-main';
import PartSectionContainer from '@/components/UI/part-section-container/part-section-container';
import { IListCases } from '@/contentful/utils/cases';

import styles from './part-cases.module.scss';

interface IPartCasesProps {
  data: {
    lang: string;
    category: string | undefined;
    title: string | undefined;
    cases: IListCases[];
    button: string | undefined;
  };
}

const PartCases: FC<IPartCasesProps> = ({ data }) => (
  <section className={styles.container}>
    <PartSectionContainer advantage={data.category ?? ''} title={data.title ?? ''}>
      <PartCasesContainerMain cases={data.cases} lang={data.lang} button={data.button} />
    </PartSectionContainer>
  </section>
);

export default PartCases;
