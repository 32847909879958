/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { FC } from 'react';

import styles from './part-checkbox.module.scss';

interface IPartCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

const PartCheckbox: FC<IPartCheckboxProps> = ({ checked, onChange, label }) => (

  <div>
    <input
      className={styles.checkbox}
      id="checkbox"
      name="checkbox"
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
    <label htmlFor="checkbox" className={styles.label}>
      {label}
    </label>
  </div>
);

export default PartCheckbox;
