'use client';

import Image from 'next/image';
import React, { FC } from 'react';

import PartButton from '@/components/UI/part-button/part-button';
import PartSectionContainer from '@/components/UI/part-section-container/part-section-container';
import { IListItem } from '@/contentful/utils/listItem';
import { IWhyChooseUs } from '@/contentful/utils/whyChoseUs';

import styles from './part-why-choose-us.module.scss';

interface IPartWhyChooseUsProps {
  data: {
    lang: string;
    category: string | undefined;
    title: string | undefined;
    description: string | undefined;
    whyUs: IWhyChooseUs;
    listItems: IListItem[];
  };
}

const PartWhyChooseUs: FC<IPartWhyChooseUsProps> = ({ data }) => {
  const {
    category, title, description, whyUs, listItems,
  } = data;

  const firstAdvantages = listItems.slice(0, 3);
  const secondAdvantages = listItems.slice(3, 6);

  return (
    <section className={styles.container}>
      <PartSectionContainer
        advantage={category ?? ''}
        title={title ?? ''}
        description={description}
      >
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.advantage}>
              <Image
                src={whyUs.firstImage?.src ?? ''}
                alt={whyUs.firstImage?.alt ?? ''}
                width={whyUs.firstImage?.width ?? 0}
                height={whyUs.firstImage?.height ?? 0}
                className={styles.image}
                quality={75}
              />
              <ul className={styles.advantageContent}>
                {firstAdvantages.map((item) => (
                  <li key={item.title} className={styles.contentItem}>
                    <div className={styles.iconContainer}>
                      <Image
                        src={item.icon?.src ?? ''}
                        alt={item.icon?.alt ?? ''}
                        width={item.icon?.width ?? 0}
                        height={item.icon?.height ?? 0}
                      />
                    </div>
                    <div className={styles.textContainer}>
                      <h3 className={styles.title}>{item.title}</h3>
                      {item.description && (
                        <p className={styles.description}>{item.description}</p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.advantage}>
              <Image
                src={whyUs.secondImage?.src ?? ''}
                alt={whyUs.secondImage?.alt ?? ''}
                width={whyUs.secondImage?.width ?? 0}
                height={whyUs.secondImage?.height ?? 0}
                className={styles.image}
                quality={75}
              />

              <ul className={styles.advantageContent}>
                {secondAdvantages.map((item) => (
                  <li key={item.title} className={styles.contentItem}>
                    <div className={styles.iconContainer}>
                      <Image
                        src={item.icon?.src ?? ''}
                        alt={item.icon?.alt ?? ''}
                        width={item.icon?.width ?? 0}
                        height={item.icon?.height ?? 0}
                      />
                    </div>
                    <div className={styles.textContainer}>
                      <h3 className={styles.title}>{item.title}</h3>
                      {item.description && (
                        <p className={styles.description}>{item.description}</p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.logoContainer}>
            <Image
              src={whyUs.logo?.src ?? ''}
              alt={whyUs.logo?.alt ?? ''}
              width={whyUs.logo?.width ?? 0}
              height={whyUs.logo?.height ?? 0}
            />
          </div>
        </div>
        <div className={styles.buttonWrap}>
          <PartButton
            option="body-color"
            text={whyUs.titleButton ?? ''}
            slug={
              data.lang === 'lv'
                ? '/contact/#order'
                : `/${data.lang}/contact/#order`
            }
          />
        </div>
      </PartSectionContainer>
    </section>
  );
};

export default PartWhyChooseUs;
