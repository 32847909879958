import Image from 'next/image';
import React, { FC } from 'react';

import { IReview } from '@/contentful/utils/reviews';

import IconRating from './assets/rating.svg';

import styles from './part-review-card.module.scss';

interface ParReviewCartProps {
  review: IReview;
}

const PartReviewCart: FC<ParReviewCartProps> = ({ review }) => {
  const { title, description, image } = review;
  return (
    <div className={styles.card}>
      <Image
        src={image?.src ?? ''}
        alt={image?.alt ?? ''}
        width={image?.width ?? 0}
        height={image?.height ?? 0}
        className={styles.image}
        quality={75}
      />
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <IconRating />
        <p className={styles.text}>{description}</p>
      </div>
    </div>
  );
};

export default PartReviewCart;
