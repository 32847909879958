'use client';

import cx from 'classnames';
import React, { FC, useState } from 'react';
import { Navigation, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import PartButton from '../UI/part-button';
import { IListCases } from '@/contentful/utils/cases';

import IconArrowSide from './assets/icon-arrow-side.svg';
import PartCaseCard from './part-case-card';

import styles from './part-cases-container-main.module.scss';

interface IPartCasesContainerMainProps {
  lang: string;
  cases: IListCases[];
  full?: boolean;
  button?: string;
}

const PartCasesContainerMain: FC<IPartCasesContainerMainProps> = ({
  cases,
  lang,
  full = true,
  button,
}) => {
  const [activeFilter, setActiveFilter] = useState<string | null>('All');

  const getUniqueFilters = (items: IListCases[]) => [
    ...new Set(items.flatMap((item: IListCases) => item.filter)),
  ];

  const uniqueFilters = getUniqueFilters(cases);
  uniqueFilters.unshift('All');

  const casesNav = uniqueFilters.map((item) => ({
    title: item,
    active: item === activeFilter,
  }));

  const handleFilterClick = (filterTitle: string) => {
    setActiveFilter(filterTitle);
  };

  const filteredCases = activeFilter === 'All'
    ? cases
    : cases.filter(
      (item) => activeFilter && item.filter.includes(activeFilter),
    );
  return (
    <>
      {casesNav && full && (
        <div className={styles.casesNavWrap}>
          <ul className={styles.casesNav}>
            {casesNav.map((item) => (
              <li key={item.title}>
                <button
                  type="button"
                  className={cx(styles.casesNavItem, {
                    [styles.active]: item.active,
                  })}
                  onClick={() => handleFilterClick(item.title)}
                >
                  <span>{item.title}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.casesSwiperWrap}>
        <Swiper
          className={styles.swiperContainer}
          {...{
            modules: [Navigation, EffectCoverflow],
            speed: 300,
            navigation: {
              nextEl: `.${styles.casesNext}`,
              prevEl: `.${styles.casesPrev}`,
            },
            effect: 'coverflow',
            centeredSlides: true,
            centeredSlidesBounds: filteredCases.length > 2,
            breakpoints: {
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
                coverflowEffect: {
                  rotate: 0,
                  slideShadows: false,
                  depth: 0,
                },
              },
              768: {
                slidesPerView: 3,
                spaceBetween: -50,
                coverflowEffect: {
                  rotate: 0,
                  depth: 500,
                  scale: 1,
                  stretch: -310,
                  slideShadows: false,
                },
              },
              1509: {
                slidesPerView: 3,
                spaceBetween: -60,
                coverflowEffect: {
                  rotate: 0,
                  depth: 500,
                  scale: 1,
                  stretch: -310,
                  slideShadows: false,
                },
              },
            },
            loop: true,
            loopedSlides: 3,
            slideToClickedSlide: true,
          }}
        >
          {filteredCases.map((slide) => (
            <SwiperSlide key={slide.title}>
              <PartCaseCard caseData={slide} lang={lang} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.casesNavigation}>
          <div className={styles.casesPrev}>
            <IconArrowSide />
          </div>
          <div className={styles.casesNext}>
            <IconArrowSide />
          </div>
        </div>
      </div>
      <div className={styles.casesButtonWrap}>
        <PartButton
          option="transparent"
          text={button ?? ''}
          slug={lang === 'lv' ? '/cases' : `/${lang}/cases`}
        />
      </div>
    </>
  );
};

export default PartCasesContainerMain;
