'use client';

import React, { FC } from 'react';

import PartOrderSection from '@/components/part-order-section';
import PartResults from '@/components/part-results';
import { IMainPage } from '@/contentful/mainPage';
import { IListCases } from '@/contentful/utils/cases';
import { IDivider } from '@/contentful/utils/divider';
import { IForm } from '@/contentful/utils/form';
import { IListItem } from '@/contentful/utils/listItem';
import { IReview } from '@/contentful/utils/reviews';
import { IService } from '@/contentful/utils/services';
import { IWhyChooseUs } from '@/contentful/utils/whyChoseUs';
import { useLanguage } from '@/context/LanguageContext';

import PartMainBanner from './part-banner-main';
import PartCases from './part-cases';
import PartOurServices from './part-our-services';
import PartPartners from './part-partner/part-partner';
import PartReviewsAndContactForm from './part-reviews-and-contact-form';
import PartWhyChooseUs from './part-why-choose-us';

interface IPartHomeView {
  data: {
    mainPages: IMainPage[],
    listItemsBanner: IListItem[],
    services: IService[],
    dividers: IDivider[],
    whyChoseUs: IWhyChooseUs[],
    listItemsWhyUs: IListItem[],
    listItemsEffects: IListItem[],
    listCases: IListCases[],
    reviews: IReview[],
    forms: IForm[],
    partners: IListItem[],
  };
}

const PartHomeView: FC<IPartHomeView> = ({ data }) => {
  const { language } = useLanguage();
  const mainPage = data.mainPages.filter((item) => item.lang === language)[0];
  const mainServicesList = data.listItemsBanner.filter((item) => item.lang === language);
  const mainServices = data.services.filter((item) => item.lang === language);
  const mainDividers = data.dividers.filter((item) => item.lang === language)[0];
  const mainWhyUs = data.whyChoseUs.filter((item) => item.lang === language)[0];
  const mainWhyUsList = data.listItemsWhyUs.filter((item) => item.lang === language);
  const mainResults = data.listItemsEffects.filter((item) => item.lang === language);
  const mainCases = data.listCases.filter((item) => item.lang === language);
  const mainReviews = data.reviews.filter((item) => item.lang === language);
  const mainForm = data.forms.filter((item) => item.lang === language)[0];
  const mainPartners = data.partners;

  const mainPageBannerData = {
    lang: language,
    title: mainPage.title,
    description: mainPage.description,
    mainBanner: mainPage.mainBanner,
    mainBannerTablet: mainPage.mainBannerTablet,
    buttonBanner: mainPage.buttonBanner,
    mainServicesList: mainPage.mainServicesList,
    listItems: mainServicesList,
  };

  const mainPageOurServicesData = {
    lang: language,
    category: mainPage.sectionServicesCategory,
    title: mainPage.sectionServicesTitle,
    services: mainServices.slice(0, 6),
    button: mainPage.sectionServicesButtonTitle,
  };

  const mainPageDivider = {
    lang: language,
    title: mainDividers.title,
    description: mainDividers.description,
    buttonName: mainDividers.buttonName,
    slug: mainDividers.slug,
  };

  const mainPageWhyChooseUs = {
    lang: language,
    category: mainPage.sectionWhyUsCategory,
    title: mainPage.sectionWhyUsTitle,
    description: mainPage.sectionWhyUsSubTitle,
    whyUs: mainWhyUs,
    listItems: mainWhyUsList,
  };

  const mainPageCasesData = {
    lang: language,
    category: mainPage.sectionCasesCategory,
    title: mainPage.sectionCasesTitle,
    cases: mainCases,
    button: mainPage.sectionCasesButtonTitle,
  };

  const mainPageReviewData = {
    category: mainPage.sectionReviewCategory,
    title: mainPage.sectionReviewTitle,
    reviews: mainReviews,
    form: mainForm,
  };

  const mainPagePartnersData = {
    title: mainPage.sectionPartnersTitle,
    partners: mainPartners,
  };

  return (
    <>
      <PartMainBanner data={mainPageBannerData} />
      <PartOurServices data={mainPageOurServicesData} />
      <PartOrderSection data={mainPageDivider} />
      <PartWhyChooseUs data={mainPageWhyChooseUs} />
      <PartResults data={mainResults} />
      <PartCases data={mainPageCasesData} />
      <PartReviewsAndContactForm data={mainPageReviewData} />
      <PartPartners data={mainPagePartnersData} />
    </>
  );
};
export default PartHomeView;
