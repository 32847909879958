const defaultSiteMenu = [
  /**
   * When you change routing here, you should change filenames in "pages" folder,
   * which also used as routing
   */
  {
    title: 'Услуги',
    link: '/services',
  },
  {
    title: 'О нас',
    link: '/about-us',
  },
  {
    title: 'Наши работы',
    link: '/cases',
  },
  {
    title: 'Контакты',
    link: '/contact',
  },
];

export const headerMenu = defaultSiteMenu;

export const footerMenu = defaultSiteMenu;

export const socialLinks = [
  { slug: 'instagram', link: 'https://www.instagram.com/vissa_autoserviss/' },
  { slug: 'facebook', link: '' },
];

export const companyPhone = '+371 29552312';
export const companyPhoneSecond = '+371 27008779';

export const companyEmail = 'vissa.serviss@gmail.com';

export const companyAddress = 'Gaujas iela 9c,\n Rīga, Čiekurkalns';

export const companyAddressFull = {
  firstLine: 'Gaujas iela 9c, Čiekurkalns Rīga,',
  secondLine: 'Latvija, LV — 1006',
};

export const companyWorkingHours = 'P. - PK. 9.00 - 18.00';
export const companyWorkingHoursFull = {
  firstLine: 'Понедельник - пятница: 9:00 - 18:00',
  secondLine: 'Суббота - воскресенье: выходной',
};

export const companyBankDetailes = [
  {
    firstLine: 'SIA VISSA',
    secondLine: 'LV40003107904',
  },
  {
    firstLine: 'DnB Banka',
    secondLine: 'LV97RIKO0002930071442',
  },
];

export const formsBaseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/144163450';

export const legalConsentOptions = {
  consent: {
    consentToProcess: true, // TODO Add company name
    text: 'I agree to allow SIA LAT TEAM to store and process my personal data.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 999, // TODO Add company name
        text: 'I agree to receive marketing communications from SIA LAT TEAM',
      },
    ],
  },
};

export const BASE_URL = 'https://vissa.lv';
