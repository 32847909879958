'use client';

import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { IListCases } from '@/contentful/utils/cases';

import IconShevronRight from './assets/shevron-right.svg';

import styles from './part-case-card.module.scss';

interface IPartCaseCardProps {
  caseData: IListCases;
  lang: string;
}

const PartCaseCard: FC<IPartCaseCardProps> = ({ caseData, lang }) => (
  <Link
    className={styles.card}
    href={
      lang === 'lv' ? `/cases/${caseData.slug}` : `/${lang}/cases/${caseData.slug}`
    }
  >
    <div className={styles.cardContainer}>
      <div className={styles.imageWrap}>
        <Image
          src={caseData.tileImage?.src ?? ''}
          alt={caseData.tileImage?.alt ?? ''}
          width={caseData.tileImage?.width ?? 0}
          height={caseData.tileImage?.height ?? 0}
          className={styles.image}
          quality={75}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.iconWrap}>
          <IconShevronRight />
        </div>
        <h3 className={styles.title}>{caseData.title}</h3>
      </div>
    </div>
  </Link>
);

export default PartCaseCard;
