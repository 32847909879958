'use client';

import cx from 'classnames';
import Image from 'next/image';
import React, { FC } from 'react';

import PartButton from '@/components/UI/part-button/part-button';
import { IContentImage } from '@/contentful/utils/contentImage';
import { IListItem } from '@/contentful/utils/listItem';

import PartServices from './part-services/part-services';

import styles from './part-banner-main.module.scss';

interface IPartBannerMainProps {
  data: {
    lang: string;
    title: string;
    description: string;
    mainBanner: IContentImage | null;
    mainBannerTablet: IContentImage | null;
    buttonBanner: string;
    listItems: IListItem[];
  };
}

const PartBannerMain: FC<IPartBannerMainProps> = ({ data }) => {
  const listItems = data.listItems.map((item) => ({
    ...item,
    icon: item.icon ?? null,
  }));

  // sort listItems by orderNumber
  listItems.sort((a, b) => (a.orderNumber ?? 0) - (b.orderNumber ?? 0));

  return (
    <section className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h1 className={styles.title}>{data.title}</h1>
          <p className={styles.description}>{data.description}</p>
          <PartButton
            option="body-color"
            text={data.buttonBanner}
            slug={
              data.lang === 'lv'
                ? '/contact/#order'
                : `/${data.lang}/contact/#order`
            }
          />
        </div>
        <div
          className={cx(
            styles.bannerImageMainDesktopWrap,
            'visibleOnDesktopAndTablet',
          )}
        >
          <Image
            src={data.mainBanner?.src ?? ''}
            alt={data.mainBanner?.alt ?? ''}
            width={data.mainBanner?.width ?? 0}
            height={data.mainBanner?.height ?? 0}
            className={cx(
              styles.bannerImageMainContainer,
              'visibleOnDesktopAndTablet',
            )}
            quality={75}
            priority
          />
        </div>
        <div className={cx(styles.bannerImageMainTabletWrap, 'visibleOnPhone')}>
          <Image
            src={data.mainBannerTablet?.src ?? ''}
            alt={data.mainBannerTablet?.alt ?? ''}
            width={data.mainBannerTablet?.width ?? 0}
            height={data.mainBannerTablet?.height ?? 0}
            className={styles.bannerImageMainContainer}
            quality={75}
            priority
          />
        </div>
        <PartServices services={listItems} />
      </div>
    </section>
  );
};

export default PartBannerMain;
