import cx from 'classnames';
import React, { FC } from 'react';

import PartLazyImage from '../part-lazy-image';
import PartButton from '@/components/UI/part-button/part-button';

import imageOrderSection from './assets/order-section.png';
import imageOrderSectionPlaceholder from './assets/order-section.png?placeholder';

import styles from './part-order-section.module.scss';

interface IPartOrderSectionProps {
  data: {
    lang: string;
    title: string;
    description: string;
    buttonName: string;
    slug: string;
  };
}

const PartOrderSection: FC<IPartOrderSectionProps> = ({ data }) => (
  <section className={styles.container}>
    <div className={styles.wrap}>
      <PartLazyImage
        src={imageOrderSection}
        placeholder={imageOrderSectionPlaceholder}
        className={cx(styles.imageContainer, 'visibleOnDesktopAndTablet')}
        srcSet={undefined}
        alt={undefined}
      />
      <div className={styles.content}>
        <div className={styles.contentWrap}>
          <h2 className={styles.title}>{data.title}</h2>
          <p className={styles.description}>{data.description}</p>
        </div>
        <PartButton
          option="body-dark"
          text={data.buttonName ?? ''}
          slug={data.lang === 'lv' ? '/contact/#order' : `/${data.lang}/contact/#order`}
        />
      </div>
    </div>
  </section>
);

export default PartOrderSection;
