'use client';

import React, { FC } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

import PartServiceCart from '@/components/part-service-cart';
import PartButton from '@/components/UI/part-button';
import PartSectionContainer from '@/components/UI/part-section-container';
import { IService } from '@/contentful/utils/services';

import IconArrowSide from './assets/icon-arrow-side.svg';

import styles from './part-our-services.module.scss';

interface IPartOurServicesProps {
  data: {
    lang: string;
    title: string | undefined;
    category: string | undefined;
    services: IService[];
    button: string | undefined;
  };
}

const PartOurServices: FC<IPartOurServicesProps> = ({ data }) => (
  <section className={styles.partOurServices}>
    <PartSectionContainer
      advantage={data.category ?? ''}
      title={data.title ?? ''}
    >
      <div className={styles.servicesList}>
        <Swiper
          className={styles.swiperContainer}
          {...{
            modules: [Navigation],
            slidesPerView: 'auto',
            navigation: {
              nextEl: `.${styles.casesNext}`,
              prevEl: `.${styles.casesPrev}`,
            },
            breakpoints: {
              0: {
                enabled: true,
                slidesPerView: 1,
                spaceBetween: 10,
              },
              767: {
                initialSlide: -5,
                spaceBetween: 30,
                slidesPerView: 3,
              },
            },
            loop: true,
          }}
        >
          {data.services.map((service) => (
            <SwiperSlide className={styles.swiperSlide} key={service.title}>
              <PartServiceCart service={service} lang={data.lang} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.casesNavigation}>
          <div className={styles.casesPrev}>
            <IconArrowSide />
          </div>
          <div className={styles.casesNext}>
            <IconArrowSide />
          </div>
        </div>
      </div>
      <div className={styles.casesButtonWrap}>
        <PartButton
          option="transparent"
          text={data.button ?? ''}
          slug={data.lang === 'lv' ? '/services' : `/${data.lang}/services`}
        />
      </div>
    </PartSectionContainer>
  </section>
);

export default PartOurServices;
