import Image from 'next/image';
import React, { FC } from 'react';

import { IContentImage } from '@/contentful/utils/contentImage';

import styles from './part-services.module.scss';

interface IPartServicesProps {
  services: {
    title: string;
    icon: IContentImage | null;
  }[];
}

const PartServices: FC<IPartServicesProps> = ({ services }) => (
  <div className={styles.container}>
    {services.map((item) => (
      <div key={item.title} className={styles.service}>
        <Image
          src={item.icon?.src ?? ''}
          alt={item.icon?.alt ?? ''}
          width={item.icon?.width ?? 0}
          height={item.icon?.height ?? 0}
        />
        <h3 className={styles.title}>{item.title.toUpperCase()}</h3>
      </div>
    ))}
  </div>
);

export default PartServices;
