'use client';

import React, { FC } from 'react';

import { IForm } from '@/contentful/utils/form';
import { IReview } from '@/contentful/utils/reviews';

import PartContactForm from './part-contact-form/part-contact-form';
import PartReviews from './part-reviews/part-reviews';

import styles from './part-reviews-and-contact-form.module.scss';

interface IPartReviewsAndContactForm {
  data: {
    category: string | undefined;
    title: string | undefined;
    reviews: IReview[];
    form: IForm;
  };
}

const PartReviewsAndContactForm: FC<IPartReviewsAndContactForm> = ({
  data,
}) => (
  <section className={styles.container}>
    <div className={styles.wrap}>
      <PartReviews
        dataReview={{ category: data.category ?? '', title: data.title ?? '' }}
        reviews={data.reviews}
      />
      <PartContactForm dataForm={data.form} />
    </div>
  </section>
);

export default PartReviewsAndContactForm;
