import React, { FC } from 'react';

import { IListItem } from '@/contentful/utils/listItem';

import styles from './part-results.module.scss';

interface PartResultsProps {
  data: IListItem[];
}

const PartResults: FC<PartResultsProps> = ({ data }) => (
  <section className={styles.container}>
    <ul className={styles.list}>
      {data.map((item) => (
        <li key={item.title} className={styles.item}>
          <div className={styles.result}>
            <span>{item.description}</span>
          </div>
          <p className={styles.description}>{item.title}</p>
        </li>
      ))}
    </ul>
  </section>
);

export default PartResults;
