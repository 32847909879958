'use client';

import cx from 'classnames';
import { StaticImageData } from 'next/image'; // Import StaticImageData
import React, {
  useEffect, useRef, useState, FC,
} from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import styles from './part-lazy-image.module.scss';

interface PartLazyImageProps {
  placeholder?: string | StaticImageData; // Use StaticImageData here
  src: string | StaticImageData; // Use StaticImageData here
  srcSet?: string;
  className?: string;
  alt?: string;
}

const PartLazyImage: FC<PartLazyImageProps> = ({
  placeholder,
  src,
  srcSet,
  className,
  alt,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const ref = useRef<HTMLImageElement>(null);

  const onLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) onLoad();
  }, []);

  return (
    <div
      className={cx(styles.container, {
        [styles.hasPlaceholder]: placeholder,
        [styles.noPlaceholder]: !placeholder,
      }, className)}
      data-loaded={isLoaded ? '1' : undefined}
    >
      <noscript>
        <img
          className={styles.noScriptImage}
          src={typeof src === 'string' ? src : src.src}
          srcSet={srcSet}
          alt={alt}
        />
      </noscript>
      {placeholder && (
        <img
          src={typeof placeholder === 'string' ? placeholder : placeholder.src}
          className={cx(styles.placeholder, styles.noScriptDisplayNone)}
          alt={alt}
        />
      )}
      <LazyLoadComponent
        visibleByDefault={isLoaded}
        delayTime={0}
        placeholder={<div className={styles.image} />}
      >
        <img
          ref={ref}
          src={typeof src === 'string' ? src : src.src}
          srcSet={srcSet}
          className={cx(styles.image, styles.noScriptDisplayNone)}
          onLoad={onLoad}
          alt={alt}
        />
      </LazyLoadComponent>
    </div>
  );
};

export default PartLazyImage;
